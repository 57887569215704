.weblogin{
    height: 40px;
    width: 120px;
    background-color: #001F3F;
    color: #ffc631;
    border: none;
}
.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
  outline: none; 
  border: none; }
  
  .navbar-toggler span {
    display: block !important;
    background-color: #ffc631 !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  