    /* box-shadow: 1px -10px 0 0 var(--clr);

/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} */

:root {
    --clr : #fff;

}

.navigation{
    position: relative;
    width: 450px;
    height: 70px;
    background: blueviolet;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.navigation ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }

.navigation ul li{
    /* position: relative;
    list-style: none;*/
    z-index: 1; 
    padding: 0;
    margin: 0;
    text-align: center;
    position: relative;
}
.navigation .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

.navigation ul li a .icon{
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
    color: var(--clr);
}
.navigation ul li.active a .icon{
    transform: translateY(-32px);
}
.navigation ul li a .text{
    position: absolute;
    margin-top:30px;
    color: var(--clr);
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

.navigation ul li.active a .text{
    opacity: 1;
    transform: translateY(10px);
}   
.navigation .indicator{
    position: absolute;
    top: -20%;
    left: 0;
    width: 70px;
    height: 70px;
    background: rgb(141, 90, 188);
    border-radius: 50%;
    border: 6px solid white;
    transition: 0.5s;
    /* transform: translateX(calc(90px * (var(--active-index) - 0))); */
    transform: translateX(calc((450px / 5) * var(--active-index) + ((450px / 5 - 70px) / 2)));

}
.indicator::before{
    content: '';
    position: absolute;
    top: 40%;
    left: -11px;
    width: 12px;
    height: 20px;
    border-top-left-radius: -12px;
    background: transparent;
    
}

.indicator::after{
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
} 


/* .navigation .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: calc(100% / 5); 
    background-color: #330f0f;
    border-radius: 2px;
    transition: transform 0.3s ease;
    transform: translateX(calc(90px * (var(--active-index) - 0)));
} */

 /* .navigation ul li.active ~ .indicator {
    transform: translateX(calc(63px * (var(--active-index) - 0)));
}
  */




/* .navigation ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .navigation ul li {
    flex: 1; 
    text-align: center;
    position: relative;
  }
  
  .navigation .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: calc(100% / 5); 
    background-color: #000000;
    border-radius: 2px;
    transition: transform 0.3s ease;
    transform: translateX(calc(90px * (var(--active-index) - 0)));
}
   */