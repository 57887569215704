.input-card {
  position: relative;
  margin-bottom: 1rem;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(47,116,127);
  
}
/* Remove background color and dropdown caret icon */
.no-background {
  text-align: left;
  background-color: transparent !important;
  /* border: none; */
  padding-left: 30px; /* Ensure space for the icon */
color: gray;

}

/* Remove the default dropdown caret icon */
.no-background::after {
  display: none !important;
}
/* Ensure icon and text are aligned */
.input-icon-left {
  margin-right: 10px;
  vertical-align: middle;
  position: absolute;
  left: 10px;
  top: 50%;
  color: #0b5f7b;
  transform: translateY(-50%); /* Centers the icon vertically */
}
.no-background:focus,
.no-background:hover {
  color: #0b5f7b; /* Text color when focused or hovered */
  background-color: transparent; /* Keep background transparent */
}

.form-input {
  padding-left: 40px; /* Add space for the icon */
  border-radius: 8px;
  border: 1px solid rgb(47,116,127);
  height: 40px;
  width: 100%;
}
.form-input:focus {
outline: none;
box-shadow: none;

}
.menuoption{
  color: #fff;
}

.custom-scroll-menu {
  max-height: 200px; /* Set a fixed height for the dropdown menu */
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin; /* For modern browsers: thin scrollbar */
background-color: #4e8698;
}

/* Optional: Customize scrollbar appearance */
.custom-scroll-menu::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-menu::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.custom-scroll-menu::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}



input[type="file"] {
  padding: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}


/* Photo Upload Container */
.photo-upload-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}

/* Custom File Input */
.photo-upload-input {
  display: none;
}

.photo-upload-label {
  display: inline-block;
  background-color: white;
  padding: 12px 20px;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  /* border: 1px solid ; */
  box-shadow: 1px 1px 2px gainsboro ;
  padding-top: 25px;
}



/* Uploaded Photos Section */
.uploaded-photos {
  margin-top: 20px;
}

.uploaded-photos-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.uploaded-photo-item {
  position: relative;
  text-align: center;
}

.uploaded-photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  /* box-shadow: 10px 26px 25px 13px rgba(0, 0, 0, 0.1); */

  transition: transform 0.2s ease;
}

.uploaded-photo:hover {
  transform: scale(1.05);
}

/* Remove Photo Button */
.remove-photo-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: rgba(255, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 4px 9px;
  border-radius: 10%;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}fff

.remove-photo-btn:hover {
  background-color: rgba(255, 0, 0, 1);
}

/* Container for the video upload section */
.form-group {
margin-bottom: 20px;
}

/* Style for the label */
.form-group label {
font-size: 16px;
font-weight: bold;
margin-bottom: 8px;
display: block;
color: #333;
}

/* Style for file input */
input[type="file"] {
display: none; /* Hide the default file input */
}

.file-upload-label {
display: inline-block;
background-color: white;
padding: 20px 30px;
font-size: 16px;
cursor: pointer;
border-radius: 5px;
transition: background-color 0.3s;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* .file-upload-label:hover {
background-color: #007bff;
color: white;
} */

/* Style for the selected video container */
.selected-video-container {
margin-top: 20px;
text-align: center;
}

/* Video element styling */
video {
border-radius: 10px;
max-width: 100%;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.custom-option {
  background-color: white;
  color: black;
}

.custom-option.hovered {
  background-color: black;
  color: red;
}




/* .arrow{
  position:relative;
  width:30px;
  height: 30px;
  cursor:pointer;
  transition:0.5s;
  overflow:hidden;
}

.arrow:hover{
      border:solid 2px white; 
    border-radius:50%;
}
.arrow:after{
  position:absolute;
  display:block;
  content:"";
  color:white;
  width: 20px;
  height: 15px;
  top:-1px;
  border-bottom:solid 2px;
  transform:translatex(4px);
}

.arrow:before{
  position:absolute;
  display:block;
  content:"";
  color:white;
  width: 8px;
  height: 8px;
  border-top:solid 2px;
  border-left:solid 2px;
  top:50%;
  left:2px;
  transform-origin:0% 0%;
  transform:rotatez(-45deg);

}
.arrow:hover:before{
 animation: aniArrow01 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.arrow:hover:after{
 animation: aniArrow02 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}


@keyframes aniArrow01 {
  0% {
    transform:rotatez(-45deg) translateY(30px) translateX(30px);
  }
  100% {
    transform: rotatez(-45deg) translateY(-35px) translateX(-35px);
  }
}


@keyframes aniArrow02 {
  0% {
    transform:translateX(45px);
  }
  100% {
    transform:translateX(-44px);
  }
}

@keyframes borderAni{
   0% {
    border:solid 2px white;
  }
  100% {    
    border:solid 2px white; 
    border-radius:50%;
  }
} */




.arrow {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* transform: rotate(90deg); */
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 2px solid#0c1470;
  border-right: 2px solid #0c1470;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}